import { Injectable } from '@angular/core';
import {
  ICreateVisitante,
  IListado,
  IQueryParam,
  IUpdateVisitante,
  IVisitante,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class VisitantesService {
  constructor(
    private http: HttpApiService,
    private helper: HelperService,
  ) {}

  public crear(dato: ICreateVisitante): Observable<IVisitante> {
    return this.http.post(`/visitantes`, dato);
  }

  public listar(queryParams?: IQueryParam): Observable<IListado<IVisitante>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/visitantes`, { params });
  }

  public listarPorId(id: string): Observable<IVisitante> {
    return this.http.get(`/visitantes/${id}`);
  }

  public editar(id: string, dato: IUpdateVisitante): Observable<IVisitante> {
    return this.http.put(`/visitantes/${id}`, dato);
  }

  public eliminar(id: string): Observable<IVisitante> {
    return this.http.delete(`/visitantes/${id}`);
  }
}
