import { Injectable } from '@angular/core';
import {
  IEventoSirena,
  IListado,
  IQueryParam,
  ITopEventosSirenaVecino,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';
import { saveAs } from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class EventosSirenasService {
  constructor(
    private http: HttpApiService,
    private helper: HelperService,
  ) {}

  public listar(
    queryParams?: IQueryParam,
  ): Observable<IListado<IEventoSirena>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/eventosSirenas`, { params });
  }

  public getTopByVecino(
    queryParams?: IQueryParam,
  ): Observable<ITopEventosSirenaVecino[]> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/eventosSirenas/getTopByVecino`, { params });
  }

  public exportar(queryParams?: IQueryParam): Promise<void> {
    return new Promise((resolve) => {
      const params = this.helper.getQueryParams(queryParams);

      this.http
        .get(`/eventosSirenas/exportar`, { params, responseType: 'blob' })
        .subscribe(
          (response: any) => {
            try {
              const file = new File([response], 'eventos.xlsx');
              saveAs(file);
              resolve();
            } catch (error) {
              console.error(error);
              resolve();
            }
          },
          (error: any) => {
            console.error(error);
            resolve();
          },
        );
    });
  }
}
