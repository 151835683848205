<button
  mat-button
  [matMenuTriggerFor]="columnMenu"
  class="boton-filtro fondo-85"
>
  <div style="display: flex; align-items: center">
    <mat-icon>{{ icon }}</mat-icon>
    <span class="text" style="margin-right: 0.5em">
      {{ !(isHandset$ | async) ? text : "" }}
    </span>
    <!-- <img src="assets/iconos/columnas.png" alt="columnas" style="width: 40px" /> -->
  </div>
</button>

<mat-menu class="acciones" #columnMenu="matMenu">
  <div cdkDropList class="list" (click)="$event.stopPropagation()">
    <div
      class="box"
      *ngFor="let column of columnas; let i = index"
      cdkDrag
      [cdkDragData]="{ columnIndex: i, columnTitle: column.header.label }"
      (cdkDragDropped)="columnMenuDropped($event)"
      [cdkDragDisabled]="!drag"
    >
      <div style="display: flex; align-items: center">
        <div class="custom-placeholder" *cdkDragPlaceholder></div>
        <mat-icon
          *ngIf="drag"
          cdkDragHandle
          color="warn"
          [ngClass]="{ 'cursor-move': drag }"
        >
          drag_indicator
        </mat-icon>
        <mat-checkbox
          (click)="$event.stopPropagation()"
          (change)="toggleSelectedColumn(column.header.label)"
          [checked]="!column.header.hidden"
        >
          <span class="columnas-select"> {{ column.header.label }}</span>
        </mat-checkbox>
      </div>
    </div>
  </div>
</mat-menu>
