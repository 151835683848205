import { Injectable } from '@angular/core';
import { ICrearEvento, IEvento, IListado, IQueryParam } from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class SucesosService {
  constructor(private http: HttpApiService, private helper: HelperService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IEvento>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/eventos`, { params });
  }

  public listarPorId(id: string): Observable<IEvento> {
    return this.http.get(`/eventos/${id}`);
  }

  public crear(dato: ICrearEvento): Observable<IEvento> {
    return this.http.post(`/eventos`, dato);
  }

  public editar(id: string, dato: ICrearEvento): Observable<IEvento> {
    return this.http.put(`/eventos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/eventos/${id}`);
  }

  public exportar(queryParams?: IQueryParam): Promise<void> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.getFile(`/eventos/exportar`, 'eventos.csv', params);
  }
}
