import { Injectable } from '@angular/core';
import {
  ICoordenadas,
  ICrearCategoriaVecinoInput,
  IListado,
  INuevoVecino,
  IQueryParam,
  IUpdateVecino,
  IVecino,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';
import { IImportVecino } from './importar/importar.component';
import { PAIS } from 'src/environments/contantes';

@Injectable({
  providedIn: 'root',
})
export class VecinosService {
  constructor(
    private http: HttpApiService,
    private helper: HelperService,
  ) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IVecino>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/vecinos`, { params });
  }

  public listarPorId(id: string): Observable<IVecino> {
    return this.http.get(`/vecinos/${id}`);
  }

  public crear(dato: INuevoVecino): Observable<IVecino> {
    return this.http.post(`/vecinos`, dato);
  }

  public importar(dato: IImportVecino): Observable<IVecino> {
    return this.http.post(`/vecinos/importar`, dato);
  }

  public editar(id: string, dato: IUpdateVecino): Observable<IVecino> {
    return this.http.put(`/vecinos/${id}`, dato);
  }

  public agregarCategoria(
    dato: ICrearCategoriaVecinoInput,
  ): Observable<IVecino> {
    return this.http.put(`/vecinos/categoria`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/vecinos/${id}`);
  }

  public desloguear(id: string): Observable<boolean> {
    return this.http.delete(`/vecinos/logout/id/${id}`);
  }

  public async exportar(queryParams?: IQueryParam): Promise<void> {
    const params = this.helper.getQueryParams(queryParams);

    return await this.http.getFile(`/vecinos/exportar`, 'vecinos.xlsx', params);
  }

  public async exportarSmartCity(): Promise<void> {
    return await this.http.getFile(
      `/vecinos/exportar/smartcity`,
      'vecinos.xlsx',
    );
  }

  public direcciones(
    text: string,
    coordenadas?: ICoordenadas,
  ): Observable<{ resultados: string[] }> {
    return this.http.post(`/vecinos/direcciones`, { text, PAIS, coordenadas });
  }
}
