import { Injectable } from '@angular/core';
import {
  IAcceso,
  IAccesoGrouped,
  ICreateAcceso,
  IListado,
  IQueryParam,
  IUpdateAcceso,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class AccesosService {
  constructor(
    private http: HttpApiService,
    private helper: HelperService,
  ) {}

  public crear(dato: ICreateAcceso): Observable<IAcceso> {
    return this.http.post(`/accesos`, dato);
  }

  public listar(queryParams?: IQueryParam): Observable<IListado<IAcceso>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/accesos`, { params });
  }

  public listarAgrupados(
    queryParams?: IQueryParam,
  ): Observable<IListado<IAccesoGrouped>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/accesos/grouped`, { params });
  }

  public listarPorDniAgrupados(
    dni: string,
  ): Observable<IListado<IAccesoGrouped>> {
    return this.http.get(`/accesos/grouped/dni/${dni}`);
  }

  public listarPorDominioAgrupados(
    dominio: string,
  ): Observable<IListado<IAccesoGrouped>> {
    return this.http.get(`/accesos/grouped/dominio/${dominio}`);
  }

  public listarPorId(id: string): Observable<IAcceso> {
    return this.http.get(`/accesos/${id}`);
  }

  public editar(id: string, dato: IUpdateAcceso): Observable<IAcceso> {
    return this.http.put(`/accesos/${id}`, dato);
  }

  public registrarSalida(id: string): Observable<IAcceso> {
    return this.http.put(`/accesos/salida/${id}`, {});
  }

  public registrarSalidaByIdAcceso(id: string): Observable<IAcceso> {
    return this.http.put(`/accesos/salida/idAcceso/${id}`, {});
  }

  public eliminar(id: string): Observable<IAcceso> {
    return this.http.delete(`/accesos/${id}`);
  }

  public eliminarByIdAcceso(id: string): Observable<IAcceso> {
    return this.http.delete(`/accesos/idAcceso/${id}`);
  }
}
