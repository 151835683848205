import { Injectable } from '@angular/core';
import {
  ICrearMensaje,
  IListado,
  IMensaje,
  INotificacion,
  IQueryParam,
  ISendNotificacion,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class NotificacionesService {
  constructor(private http: HttpApiService, private helper: HelperService) {}

  public listar(
    queryParams?: IQueryParam
  ): Observable<IListado<INotificacion>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/notificaciones`, { params });
  }

  public send(dato: ISendNotificacion): Observable<{ message: string }> {
    return this.http.post(`/notificaciones/send`, dato);
  }
}
