import { Injectable } from '@angular/core';
import {
  IListado,
  IAuditoria,
  ICreateAuditoria,
  IQueryParam,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({ providedIn: 'root' })
export class AuditoriasService {
  constructor(private htpp: HttpApiService, private helper: HelperService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IAuditoria>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.htpp.get(`/auditorias`, { params });
  }

  public crear(dato: ICreateAuditoria): Observable<IAuditoria> {
    return this.htpp.post(`/auditorias`, dato);
  }

  public listarPorId(id: string): Observable<IAuditoria> {
    return this.htpp.get(`/auditorias/${id}`);
  }

  public eliminar(id: string): Observable<void> {
    return this.htpp.delete(`/auditorias/${id}`);
  }
}
