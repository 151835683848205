import { Injectable } from '@angular/core';
import {
  IConfigTurno,
  ICreateConfigTurno,
  IListado,
  IQueryParam,
  IUpdateConfigTurno,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { HttpApiService } from 'src/app/auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class ConfigTurnosService {
  constructor(
    private http: HttpApiService,
    private helper: HelperService,
  ) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IConfigTurno>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/configturnos`, { params });
  }

  public listarPorId(id: string): Observable<IConfigTurno> {
    return this.http.get(`/configturnos/${id}`);
  }

  public crear(dato: ICreateConfigTurno): Observable<IConfigTurno> {
    return this.http.post(`/configturnos`, dato);
  }

  public editar(
    id: string,
    dato: IUpdateConfigTurno,
  ): Observable<IConfigTurno> {
    return this.http.put(`/configturnos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/configturnos/${id}`);
  }

  ///////

  static numeroADia(n: number): string {
    switch (n) {
      case 0:
        return 'Domingo';
      case 1:
        return 'Lunes';
      case 2:
        return 'Martes';
      case 3:
        return 'Miércoles';
      case 4:
        return 'Jueves';
      case 5:
        return 'Viernes';
      case 6:
        return 'Sábado';
      default:
        return '';
    }
  }

  static getHourFromDate(date: Date): string {
    return date.toTimeString().slice(0, 5);
  }
}
