import { DragDropModule } from '@angular/cdk/drag-drop';
import { CommonModule } from '@angular/common';
import { provideHttpClient } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { GalleryModule } from 'ng-gallery';
import { NgxAudioPlayerModule } from 'ngx-audio-player';
import { CountdownModule } from 'ngx-countdown';
import { AudioRecorderModule } from './audio-recorder/audio-recorder.module';
import { ChartModule } from './chart/chart.module';
import { ColumnSelectModule } from './column-select/column-select.module';
import { DialogModule } from './dialog/dialog.module';
import { FiltroTablaModule } from './filtro-tabla/filtro-tabla.module';
import { MaterialModule } from './material.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChangelogModule } from './changelog/changelog.module';
import { TextareaModule } from './textarea/module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import {
  NgxMatDatetimePickerModule,
  NgxMatNativeDateModule,
  NgxMatTimepickerModule,
} from '@angular-material-components/datetime-picker';
import { MarkerLocationModule } from './marker-location/marker-location.module';
import { AutocompleteDireccionModule } from './autocomplete-direccion/autocomplete-direccion.module';
import { DireccionModule } from './direccion/module';
import { TablaComponent } from './tabla/tabla.component';
import { CarruselModule } from './carrusel/carrusel.module';
import {
  OwlDateTimeModule,
  OwlNativeDateTimeModule,
} from '@danielmoncada/angular-datetime-picker';
import { NgxColorsModule } from 'ngx-colors';
import { UbicarOlComponent } from './ubicar-ol/ubicar-ol.component';

@NgModule({
  imports: [
    CommonModule,
    SimpleNotificationsModule.forRoot(),
    TablaComponent,
    UbicarOlComponent,
  ],
  exports: [
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    DragDropModule,
    FlexLayoutModule,
    SimpleNotificationsModule,
    CountdownModule,
    NgxAudioPlayerModule,
    GalleryModule,
    NgSelectModule,
    NgxMaterialTimepickerModule,
    NgxMatDatetimePickerModule,
    NgxMatTimepickerModule,
    NgxMatNativeDateModule,
    //
    MaterialModule,
    ColumnSelectModule,
    DialogModule,
    FiltroTablaModule,
    ChartModule,
    AudioRecorderModule,
    ChangelogModule,
    TextareaModule,
    MarkerLocationModule,
    AutocompleteDireccionModule,
    DireccionModule,
    CarruselModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxColorsModule,
    // Componentes
    TablaComponent,
    UbicarOlComponent,
  ],
  providers: [provideHttpClient()],
})
export class AuxiliaresModule {}
