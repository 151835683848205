<mat-form-field>
  <mat-label>Partido</mat-label>
  <input
    matInput
    placeholder="Partido"
    [(ngModel)]="localidad"
    type="text"
    (change)="cambioLocalidad()"
  />
</mat-form-field>

<div fxLayout="row" fxLayoutGap="15px">
  <mat-form-field fxFlex="75">
    <mat-label>Calle</mat-label>
    <input
      matInput
      placeholder="San Martín"
      [(ngModel)]="calle"
      type="text"
      autocomplete="street-address"
      (change)="cambioCalle()"
    />
  </mat-form-field>

  <mat-form-field fxFlex="25">
    <mat-label>Número</mat-label>
    <input
      #inputDireccion
      matInput
      type="number"
      placeholder="1234"
      [(ngModel)]="numero"
      (change)="cambioNumero()"
    />
  </mat-form-field>
</div>

<mat-form-field>
  <mat-label>{{
    !results.length ? "Completá la calle y el número" : "Dirección"
  }}</mat-label>
  <mat-select (selectionChange)="cambioDireccion()" [(ngModel)]="direccion">
    <mat-option *ngFor="let e of results" [value]="e">
      {{ e }}
    </mat-option>
  </mat-select>

  <mat-hint *ngIf="!direccion">
    <span style="color: red">
      Seleccioná la dirección correcta de la lista
    </span>
  </mat-hint>
</mat-form-field>
