import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { firstValueFrom, fromEvent } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
} from 'rxjs/operators';
import { DireccionesService } from '../../servicios/direcciones.service';

@Component({
  selector: 'app-autocomplete-direccion',
  templateUrl: './autocomplete-direccion.component.html',
  styleUrls: ['./autocomplete-direccion.component.scss'],
})
export class AutocompleteDireccionComponent implements OnInit {
  public loading = false;
  public direcciones: string[] = [];

  @ViewChild('inputDireccion', { static: true }) inputDireccion?: ElementRef;

  @Input() direccion = '';
  @Output() direccionChange = new EventEmitter<string>();

  constructor(private direccionesService: DireccionesService) {}

  public onDireccionChange(direccion: string): void {
    this.direccionChange.emit(direccion);
  }

  private listenDirChange(): void {
    fromEvent(this.inputDireccion?.nativeElement, 'keyup')
      .pipe(
        map((e: any) => e.target.value),
        filter(() => !this.loading),
        debounceTime(500),
        distinctUntilChanged(),
      )
      .subscribe(async () => {
        const res = await firstValueFrom(
          this.direccionesService.direcciones(this.direccion),
        );
        this.direcciones = res.resultados;
      });
  }

  ngOnInit(): void {
    this.listenDirChange();
  }
}
