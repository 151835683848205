import { Injectable } from '@angular/core';
import {
  ICentroMonitoreo,
  IListado,
  ICreateCentroMonitoreo,
  IQueryParam,
  IUpdateCentroMonitoreo,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class CentrosMonitoreoService {
  constructor(
    private http: HttpApiService,
    private helper: HelperService,
  ) {}

  public listar(
    queryParams?: IQueryParam,
  ): Observable<IListado<ICentroMonitoreo>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/centrosMonitoreo`, { params });
  }

  public listarPorId(id: string): Observable<ICentroMonitoreo> {
    return this.http.get(`/centrosMonitoreo/${id}`);
  }

  public crear(dato: ICreateCentroMonitoreo): Observable<ICentroMonitoreo> {
    return this.http.post(`/centrosMonitoreo`, dato);
  }

  public editar(
    id: string,
    dato: IUpdateCentroMonitoreo,
  ): Observable<ICentroMonitoreo> {
    return this.http.put(`/centrosMonitoreo/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/centrosMonitoreo/${id}`);
  }
}
