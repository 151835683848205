<mat-card>
  <mat-card-header>
    <mat-card-title
      cdkDrag
      cdkDragHandle
      cdkDragRootElement=".cdk-overlay-pane"
    >
      {{ title }}
    </mat-card-title>
  </mat-card-header>

  <!-- Form -->
  <mat-card-content style="margin: 2em 0; max-height: 65vh; overflow: auto">
    {{ message }}

    <mat-form-field class="mt-3">
      <mat-label>{{ inputName }}</mat-label>
      <input matInput [(ngModel)]="input" />
    </mat-form-field>
  </mat-card-content>

  <mat-divider></mat-divider>

  <!-- Botones -->
  <mat-card-actions fxLayoutGap="1em" style="margin: 0.5em">
    <button
      mat-raised-button
      color="primary"
      (click)="matDialogRef.close(input.trim())"
    >
      {{ btnOkText }}
    </button>
    <button mat-raised-button (click)="matDialogRef.close(false)">
      {{ btnCancelText }}
    </button>
  </mat-card-actions>

  <!-- Progress Bar  -->
  <!-- <mat-card-footer>
      <mat-progress-bar
        [mode]="loading ? 'indeterminate' : 'determinate'"
      ></mat-progress-bar>
    </mat-card-footer> -->
</mat-card>
