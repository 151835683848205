<section class="layout">
  <div class="header">
    <span style="justify-content: center; text-align: center; display: flex">{{
      direccion
    }}</span>
  </div>
  <div class="main">
    <div id="map" class="map"></div>
  </div>
</section>
