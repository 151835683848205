<div style="display: flex; align-items: center">
  <!-- Input Search -->
  <form *ngIf="search" autocomplete="off" style="width: 100%">
    <mat-form-field class="no-hint form-field-primary">
      <mat-label>Buscar</mat-label>
      <input
        matInput
        type="text"
        #inputSearch
        [(ngModel)]="search.value"
        [ngModelOptions]="{ standalone: true }"
        class="input-search"
      />

      <button
        *ngIf="search.value"
        matSuffix
        mat-icon-button
        type="button"
        aria-label="Clear"
        (click)="search.value = ''; cambioFiltro()"
      >
        <mat-icon>close</mat-icon>
      </button>
      <!-- <img
        *ngIf="!search.value"
        matSuffix
        src="assets/iconos/buscar.png"
        alt="buscar"
        style="width: 40px; margin-right: 0.5em; cursor: pointer"
      />
      <img
        *ngIf="search.value"
        matSuffix
        src="assets/iconos/cerrar.png"
        alt="cerrar"
        style="width: 40px; margin-right: 0.5em; cursor: pointer"
        (click)="search.value = ''; cambioFiltro()"
      /> -->
    </mat-form-field>
  </form>

  <div *ngIf="filtros?.length">
    <!-- Boton Filtro -->

    <!-- <div class="filter-cnt"> -->
    <button
      mat-button
      [matMenuTriggerFor]="filterMenu"
      [ngStyle]="{ color: filtroActivo ? 'red' : 'unset' }"
      class="boton-filtro fondo-85"
    >
      <div class="row-center">
        <mat-icon>filter_alt</mat-icon>
        <span class="text" style="margin-right: 0.5em">
          {{ (isHandset$ | async) ? "" : "Filtro" }}
        </span>

        <!-- <img
          matSuffix
          src="assets/iconos/filtro.png"
          alt="filtro"
          style="width: 40px"
        /> -->
      </div>
    </button>

    <!-- Elementos Menu Filtro -->
    <mat-menu #filterMenu="matMenu">
      <div (click)="$event.stopPropagation()" style="padding: 1em">
        <p style="text-align: right; font-weight: 500">
          <span (click)="limpiarFiltros()" style="cursor: pointer" class="text">
            Limpiar filtros
          </span>
        </p>
        <form autocomplete="off">
          <ng-container *ngFor="let dato of filtros; let i = index">
            <!-- Select -->
            <mat-form-field *ngIf="dato.tipo === 'select'">
              <mat-label>{{ dato.label }}</mat-label>
              <mat-select
                [name]="dato.label"
                [(ngModel)]="dato.filter.value"
                (selectionChange)="cambioFiltro()"
              >
                <mat-option
                  class="text"
                  *ngFor="let elemento of dato.elementos"
                  [value]="
                    dato.selectValue ? elemento[dato.selectValue] : elemento
                  "
                >
                  {{ dato.selectLabel ? elemento[dato.selectLabel] : elemento }}
                </mat-option>
              </mat-select>
              <button
                *ngIf="dato.filter.value"
                matSuffix
                mat-icon-button
                type="button"
                aria-label="Clear"
                (click)="
                  dato.filter.value = undefined;
                  $event.stopPropagation();
                  cambioFiltro()
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>

            <!-- Input -->
            <mat-form-field *ngIf="dato.tipo === 'input'">
              <mat-label>{{ dato.label }}</mat-label>
              <input
                matInput
                type="search"
                [name]="dato.label"
                [(ngModel)]="dato.filter.value"
                (ngModelChange)="cambioFiltro()"
              />
            </mat-form-field>

            <!-- Date Range -->
            <mat-form-field *ngIf="dato.tipo === 'dateRange'">
              <mat-label>{{ dato.label }}</mat-label>
              <mat-date-range-input [rangePicker]="picker">
                <input
                  matStartDate
                  placeholder="Desde"
                  [name]="dato.label"
                  [(ngModel)]="dato.desde"
                  (ngModelChange)="cambioFiltro()"
                />
                <input
                  matEndDate
                  placeholder="Hasta"
                  [name]="dato.label"
                  [(ngModel)]="dato.hasta"
                  (ngModelChange)="cambioFiltro()"
                />
              </mat-date-range-input>
              <mat-datepicker-toggle
                matSuffix
                [for]="picker"
              ></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>

            <!-- Ng-select async -->
            <ng-select
              *ngIf="dato.tipo === 'ngselect-async'"
              [items]="asyncData$![i] | async"
              [typeahead]="asyncInput$![i]"
              [minTermLength]="3"
              [placeholder]="dato.label"
              [bindLabel]="dato.selectLabel!"
              [bindValue]="dato.selectValue!"
              [minTermLength]="3"
              loadingText="Cargando..."
              typeToSearchText="Buscar..."
              notFoundText="No se encontraron resultados"
              appendTo="body"
              (change)="cambioFiltro()"
              [(ngModel)]="dato.filter.value"
              [ngModelOptions]="{ standalone: true }"
            >
            </ng-select>

            <!-- Ng-select -->
            <ng-select
              *ngIf="dato.tipo === 'ngselect'"
              [items]="dato.elementos!"
              [placeholder]="dato.label"
              [bindLabel]="dato.selectLabel!"
              [bindValue]="dato.selectValue!"
              loadingText="Cargando..."
              typeToSearchText="Buscar..."
              notFoundText="No se encontraron resultados"
              appendTo="body"
              (change)="cambioFiltro()"
              [(ngModel)]="dato.filter.value"
              [ngModelOptions]="{ standalone: true }"
              [multiple]="!!dato.multiple"
              [closeOnSelect]="!dato.multiple"
            >
            </ng-select>

            <!-- RegExp -->
            <mat-form-field *ngIf="dato.tipo === 'regexp'">
              <mat-label>{{ dato.label }}</mat-label>
              <input
                #inputSearch
                matInput
                type="search"
                [placeholder]="dato.label"
                [(ngModel)]="dato.filter.value"
                [ngModelOptions]="{ standalone: true }"
              />
              <button
                *ngIf="dato.filter.value"
                matSuffix
                mat-icon-button
                type="button"
                aria-label="Clear"
                (click)="
                  dato.filter.value = undefined;
                  $event.stopPropagation();
                  cambioFiltro()
                "
              >
                <mat-icon>close</mat-icon>
              </button>
            </mat-form-field>
          </ng-container>
        </form>
      </div>
    </mat-menu>
  </div>
</div>
