.columnas {
  .mat-mdc-menu-content {
    border-top: 10px solid var(--accent-color);
    background: var(--bg-color);
    color: var(--fg-color);
    padding: 0 !important;
  }
}

.list {
  // margin: 0 0 1em 0;
  width: 100%;
  max-width: 100%;
  // border: solid 1px #ccc;
  min-height: 60px;
  display: block;
  // background: white;
  border-radius: 4px;
  overflow: hidden;
}

.box {
  padding: 15px 10px;
  border-bottom: solid 1px #ccc;
  // color: rgba(0, 0, 0, 0.87);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  // cursor: move;
  // background: white;
  font-size: 14px;
}

.cursor-move {
  cursor: move;
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.box:last-child {
  border: none;
}

.list.cdk-drop-list-dragging .box:not(.cdk-drag-placeholder) {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.custom-placeholder {
  background: var(--accent-color);
  border: dotted 3px var(--negro-transparente);
  min-height: 60px;
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.centerRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 12px;
}
