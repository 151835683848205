import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  Output,
  ViewChildren,
} from '@angular/core';
import { EventEmitter } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { NgSelectModule } from '@ng-select/ng-select';
import { IFilter, IQueryParam } from 'modelos/src';
import { fromEvent, Observable, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
} from 'rxjs/operators';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import {
  MatMenuModule,
  MAT_MENU_DEFAULT_OPTIONS,
} from '@angular/material/menu';

export interface IFiltroTabla {
  // Campos comunes
  filter: IFieldFilter;
  label: string; // Etiqueta para mostrar
  tipo:
    | 'select'
    | 'input'
    | 'dateRange'
    | 'ngselect-async'
    | 'ngselect'
    | 'regexp'; // Tipo de campo a mostrar
  // Para select
  elementos?: any[]; // Arreglo de elementos (para el select)
  selectValue?: string; // Valor a seleccionar de los elementos en el select (para select)
  selectLabel?: string; // Valor a mostrar de los elementos en el select (para select)
  multiple?: boolean; // Para select multiple
  // Para dateRange
  desde?: string;
  hasta?: string;
  // Para ngselect-async
  asyncFunction?: string;
  searchOn?: string[];
  populate?: string;
}

export interface IRegExpSearch {
  fields: string[];
  value?: string;
}

export interface IFieldFilter {
  field: string;
  value?: any;
}

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatFormFieldModule,
    MatIconModule,
    MatButtonModule,
    MatMenuModule,
    MatSelectModule,
    MatDatepickerModule,
    MatInputModule,
    NgSelectModule,
  ],
  selector: 'app-filtro',
  templateUrl: './filtro.component.html',
  styleUrls: ['./filtro.component.scss'],
  providers: [
    {
      provide: MAT_MENU_DEFAULT_OPTIONS,
      useValue: { overlayPanelClass: 'shadow' },
    },
  ],
})
export class FiltroComponent implements OnInit, AfterViewInit {
  // @ViewChild('inputSearch', { static: false }) inputSearch?: ElementRef;
  @ViewChildren('inputSearch') inputSearch?: ElementRef[];
  @Input() filtros: IFiltroTabla[] = [];
  @Input() search?: IRegExpSearch;

  @Input() query!: IQueryParam;
  @Output() queryChange = new EventEmitter<IQueryParam>();

  public asyncData$?: Observable<any[]>[] = [];
  public asyncInput$?: Subject<string>[] = [];

  public filtroActivo: boolean = false;

  public isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(
      map((result) => result.matches),
      shareReplay(),
    );

  constructor(private breakpointObserver: BreakpointObserver) {}

  private crearQuery(): IFilter<any> {
    const filters: IFilter<any> = {
      $and: [],
    };
    this.filtros.forEach((dato) => {
      if (
        dato.tipo === 'input' ||
        dato.tipo === 'select' ||
        dato.tipo === 'ngselect-async' ||
        dato.tipo === 'ngselect'
      ) {
        if (dato.filter.value && dato.filter.field) {
          filters.$and!.push({ [dato.filter.field]: dato.filter.value });
        }
      } else if (dato.tipo === 'dateRange') {
        if (dato.filter.field && (dato.desde || dato.hasta)) {
          const filtroFecha: { $and: object[] } = {
            $and: [],
          };
          if (dato.desde) {
            const date = new Date(dato.desde);
            date.setHours(0, 0, 0, 0);
            filtroFecha.$and.push({
              [dato.filter.field]: { $gte: date.toISOString() },
            });
          }
          if (dato.hasta) {
            const date = new Date(dato.hasta);
            date.setHours(23, 59, 59, 999);
            filtroFecha.$and.push({
              [dato.filter.field]: { $lte: date.toISOString() },
            });
          }
          filters.$and!.push(filtroFecha as any);
        }
      } else if (dato.tipo === 'regexp') {
        if (dato.filter.field && dato.filter.value) {
          const sanitizedValue = this.sanitizeValue(dato.filter.value);
          filters.$and!.push({
            [dato.filter.field]: { $regex: sanitizedValue, $options: 'i' },
          });
        }
      }
    });
    if (this.search?.value) {
      const sanitizedValue = this.sanitizeValue(this.search.value);
      console.log('sanitizedValue', sanitizedValue);
      const search: any = {
        $or: [],
      };
      for (const field of this.search.fields) {
        search.$or.push({
          [field]: { $regex: sanitizedValue, $options: 'i' },
        });
      }
      filters.$and!.push(search);
    }
    if (filters.$and!.length === 0) {
      delete filters.$and;
    }
    return filters;
  }

  public updatefiltroActivo() {
    const selected = this.filtros.find(
      (dato) => dato.filter.value || dato.desde || dato.hasta,
    );
    this.filtroActivo = selected ? true : false;
  }

  public async cambioFiltro() {
    this.updatefiltroActivo();
    const filter = this.crearQuery();
    this.query.filter = JSON.stringify(filter);
    this.queryChange.emit(this.query);
  }

  public async limpiarFiltros() {
    this.filtros.forEach((dato) => {
      dato.filter.value = undefined;
      dato.desde = undefined;
      dato.hasta = undefined;
    });
    this.cambioFiltro();
  }

  private initSearchChange(): void {
    if (this.inputSearch?.length) {
      this.inputSearch.forEach((input) => {
        fromEvent(input.nativeElement, 'keyup')
          .pipe(
            map((e: any) => e.target.value),
            debounceTime(500),
            distinctUntilChanged(),
          )
          .subscribe(() => {
            this.cambioFiltro();
          });
      });
    }
  }

  /**
   * Elimina todos los caracteres que no sean alfanuméricos, vocales con acento o espacios del string
   */
  private sanitizeValue(value: string): any {
    return value.replace(/[^A-Za-záéíóúñÁÉÍÓÚÑ0-9\s]/g, '');
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.initSearchChange();
  }
}
