import { Injectable } from '@angular/core';
import {
  ICreateVehiculo,
  IListado,
  IQueryParam,
  IUpdateVehiculo,
  IVehiculo,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class VehiculosService {
  constructor(
    private http: HttpApiService,
    private helper: HelperService,
  ) {}

  public crear(dato: ICreateVehiculo): Observable<IVehiculo> {
    return this.http.post(`/vehiculos`, dato);
  }

  public listar(queryParams?: IQueryParam): Observable<IListado<IVehiculo>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/vehiculos`, { params });
  }

  public listarPorId(id: string): Observable<IVehiculo> {
    return this.http.get(`/vehiculos/${id}`);
  }

  public editar(id: string, dato: IUpdateVehiculo): Observable<IVehiculo> {
    return this.http.put(`/vehiculos/${id}`, dato);
  }

  public eliminar(id: string): Observable<IVehiculo> {
    return this.http.delete(`/vehiculos/${id}`);
  }
}
