import { Injectable } from '@angular/core';
import {
  ICreateTurno,
  IListado,
  IQueryParam,
  ITurno,
  IUpdateTurno,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from 'src/app/auxiliares/servicios/helper.service';
import { HttpApiService } from 'src/app/auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class TurnosService {
  constructor(
    private http: HttpApiService,
    private helper: HelperService,
  ) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<ITurno>> {
    const params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/turnos`, { params });
  }

  public listarPorId(id: string): Observable<ITurno> {
    return this.http.get(`/turnos/${id}`);
  }

  public crear(dato: ICreateTurno): Observable<ITurno> {
    return this.http.post(`/turnos`, dato);
  }

  public editar(id: string, dato: IUpdateTurno): Observable<ITurno> {
    return this.http.put(`/turnos/${id}`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/turnos/${id}`);
  }
}
