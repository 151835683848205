import { Injectable } from '@angular/core';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import { OSM, Vector } from 'ol/source';
import Fill from 'ol/style/Fill';
import Icon from 'ol/style/Icon';
import Stroke from 'ol/style/Stroke';
import Style from 'ol/style/Style';
import { MouseWheelZoom } from 'ol/interaction';
import { defaults as defaultInteractions } from 'ol/interaction';
import { Coordinate } from 'ol/coordinate';
import { LineString } from 'ol/geom';
import { fromLonLat, transform } from 'ol/proj';
import { getLength } from 'ol/sphere';
import { ICoordenadas } from 'modelos/src';
import VectorSource from 'ol/source/Vector';
import Text from 'ol/style/Text';

@Injectable({
  providedIn: 'root',
})
export class OpenLayersService {
  public static mapTile() {
    return new TileLayer({
      source: new OSM(),
    });
  }

  public static drawVectorLayer() {
    return new VectorLayer({
      source: new VectorSource({ wrapX: false }),
      style: {
        'fill-color': 'rgba(255, 255, 255, 0.2)',
        'stroke-color': '#ffcc33',
        'stroke-width': 2,
        'circle-radius': 7,
        'circle-fill-color': '#ffcc33',
      },
    });
  }

  public static sirenasVectorLayer() {
    return new VectorLayer({
      source: new Vector({}),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'assets/map/sirenas/verde.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static sirenaStyleVerde() {
    return new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        src: 'assets/map/sirenas/verde.png',
        height: 35,
        width: 35,
      }),
    });
  }

  public static sirenaStyleRoja() {
    return new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        src: 'assets/map/sirenas/roja.png',
        height: 35,
        width: 35,
      }),
    });
  }

  public static sirenaStyleAmarilla() {
    return new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        src: 'assets/map/sirenas/amarilla.png',
        height: 35,
        width: 35,
      }),
    });
  }

  public static sirenaStyleAzul() {
    return new Style({
      image: new Icon({
        anchor: [0.5, 0.5],
        src: 'assets/map/sirenas/azul.png',
        height: 35,
        width: 35,
      }),
    });
  }

  public static circleVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        fill: new Fill({
          color: 'rgba(0, 255, 76, 0.1)',
        }),
        stroke: new Stroke({
          color: 'green',
          width: 3,
        }),
      }),
    });
  }

  public static puntoCircleVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        fill: new Fill({
          color: 'rgba(0, 183, 255, 0.1)',
        }),
        stroke: new Stroke({
          color: 'blue',
          width: 3,
        }),
      }),
    });
  }

  public static polygonsVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        fill: new Fill({
          color: 'rgba(0, 0, 0, 0.25)',
        }),
        stroke: new Stroke({
          color: 'black',
          width: 3,
        }),
      }),
    });
  }

  public static pinsVectorlayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 1],
          src: 'assets/map/pins/pin.png',
          height: 35,
          width: 35,
        }),
      }),
    });
  }

  public static alertasVectorlayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'assets/map/alertas/alertaNueva.png',
          height: 25,
          width: 25,
        }),
      }),
    });
  }

  public static eventosVectorlayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'assets/map/eventos/info.png',
          height: 25,
          width: 25,
        }),
      }),
    });
  }

  public static puntosVectorlayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'https://i.imgur.com/kbmnjCJ.png',
          height: 25,
          width: 25,
        }),
      }),
    });
  }

  public static ubicacionVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        image: new Icon({
          anchor: [0.5, 0.5],
          src: 'assets/map/alertas/ubicacion.png',
          height: 15,
          width: 15,
        }),
      }),
    });
  }

  public static polylineVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        stroke: new Stroke({
          color: 'blue',
          width: 4,
        }),
      }),
    });
  }

  public static textVectorLayer() {
    return new VectorLayer({
      source: new Vector(),
      style: new Style({
        text: new Text({
          fill: new Fill({ color: 'black' }),
        }),
      }),
    });
  }

  public static wheelInteraction() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const f = (e: any) => {
      if (e.type === 'wheel' && e.originalEvent.ctrlKey) {
        return true;
      }
      return false;
    };

    const mouseWheelOptions = new MouseWheelZoom({
      condition: f,
    });

    return mouseWheelOptions;
  }

  public static interactions() {
    return defaultInteractions({
      mouseWheelZoom: false,
      doubleClickZoom: false,
    }).extend([OpenLayersService.wheelInteraction()]);
  }

  constructor() {}

  public static async getCurrentPosition(): Promise<Coordinate> {
    return new Promise((resolve) => {
      const ubicacionBase = OpenLayersService.lonLatToCoordinate(
        -58.0128784,
        -35.5836812,
      );
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const ubicacion = OpenLayersService.lonLatToCoordinate(
              position.coords.longitude,
              position.coords.latitude,
            );
            resolve(ubicacion);
          },
          () => {
            console.error('Ubicacion no aceptada');
            resolve(ubicacionBase);
          },
          {
            timeout: 5000,
            enableHighAccuracy: true,
          },
        );
      } else {
        resolve(ubicacionBase);
      }
    });
  }

  public static lonLatToCoordinate(lon: number, lat: number): Coordinate {
    const coord = fromLonLat([lon, lat]);
    return coord;
  }

  public static distanceBetweenPoints(p1: Coordinate, p2: Coordinate): number {
    return getLength(new LineString([p1, p2]));
  }

  public static leastDistanceFromLineString(c: Coordinate, line: Coordinate[]) {
    const ls = new LineString(line);
    const cp = ls.getClosestPoint(c);
    const distance = OpenLayersService.distanceBetweenPoints(c, cp);
    return distance;
  }

  public static cordinateToGeoJSONPoint(coord: Coordinate): {
    type: 'Point';
    coordinates: [number, number];
  } {
    coord = transform(coord, 'EPSG:3857', 'EPSG:4326');
    return {
      type: 'Point',
      coordinates: [coord[0], coord[1]],
    };
  }

  public static cordinatesToGeoJSONLineString(coords: Coordinate[]): {
    type: 'LineString';
    coordinates: [number, number][];
  } {
    const coordinates: [number, number][] = coords.map((coord) => {
      coord = transform(coord, 'EPSG:3857', 'EPSG:4326');
      return [coord[0], coord[1]];
    });
    return {
      type: 'LineString',
      coordinates,
    };
  }

  public static coordinateToCoordenada(c?: Coordinate): ICoordenadas | null {
    if (!c) return null;
    const coord = transform(c, 'EPSG:3857', 'EPSG:4326');
    return { lat: coord[1], lng: coord[0] };
  }

  public static coordinatesToCoordenadas(coords: Coordinate[]): ICoordenadas[] {
    return coords.map((c) => OpenLayersService.coordinateToCoordenada(c));
  }

  public static polylineToCoordinates(
    polyline: [number, number][],
  ): Coordinate[] {
    return polyline.map((p) => fromLonLat(p));
  }

  public static polygonToCoordinates(
    polygon: [[number, number][]],
  ): Coordinate[][] {
    return polygon.map((p) => p.map((c) => fromLonLat(c)));
  }

  /// ICoordeandas

  public static coordenadaToCoordinate(c: ICoordenadas): Coordinate {
    return fromLonLat([c.lng, c.lat]);
  }

  public static coordenadasToCoordinates(coords: ICoordenadas[]): Coordinate[] {
    return coords.map((c) => fromLonLat([c.lng, c.lat]));
  }
}
