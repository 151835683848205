import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import Swiper from 'swiper';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-carrusel',
  templateUrl: './carrusel.component.html',
  styleUrl: './carrusel.component.scss',
})
export class CarruselComponent implements OnInit {
  // SWIPER
  @ViewChild('swiperRef', { static: true })
  protected _swiperRef: ElementRef | undefined;
  swiper?: Swiper;
  //
  @Input() imagenes: string[] = [];

  private _initSwiper() {
    const options: SwiperOptions = {
      pagination: { clickable: true },
      slidesPerView: 1,
      loop: false,
    };

    const swiperEl = this._swiperRef?.nativeElement;
    Object.assign(swiperEl, options);

    swiperEl.initialize();

    if (this.swiper) this.swiper.currentBreakpoint = false; // Breakpoint fixes
    this.swiper = this._swiperRef?.nativeElement.swiper;

    this.swiper?.off('slideChange'); // Avoid multiple subscription, in case you wish to call the `_initSwiper()` multiple time

    // this.swiper?.on('slideChange', () => {
    //   // Any change subscription you wish
    //   // this.infinitLoad?.triggerOnScroll();
    // });
  }

  ngOnInit() {
    this._initSwiper();
  }
}
