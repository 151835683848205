import { Injectable } from '@angular/core';
import {
  IListado,
  IQueryParam,
  IBaneoVecino,
  ICrearBaneoVecino,
} from 'modelos/src';
import { Observable } from 'rxjs';
import { HelperService } from '../../auxiliares/servicios/helper.service';
import { HttpApiService } from '../../auxiliares/servicios/http-api.service';

@Injectable({
  providedIn: 'root',
})
export class BaneosService {
  constructor(private http: HttpApiService, private helper: HelperService) {}

  public listar(queryParams?: IQueryParam): Observable<IListado<IBaneoVecino>> {
    let params = this.helper.getQueryParams(queryParams);
    return this.http.get(`/baneosVecinos`, { params });
  }

  public listarPorId(id: string): Observable<IBaneoVecino> {
    return this.http.get(`/baneosVecinos/${id}`);
  }

  public crear(dato: ICrearBaneoVecino): Observable<IBaneoVecino> {
    return this.http.post(`/baneosVecinos`, dato);
  }

  public eliminar(id: string): Observable<void> {
    return this.http.delete(`/baneosVecinos/${id}`);
  }
}
