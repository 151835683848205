import { Injectable } from '@angular/core';
import { ISocketMessage } from 'modelos/src';
import { Observable, Subject, Subscription } from 'rxjs';
import { WebSocketSubject, webSocket } from 'rxjs/webSocket';
import {
  NOMBRE_APP,
  VERSION,
  websocketServer,
} from 'src/environments/environment';
import { StoreService } from '../../entidades/login/store.service';

@Injectable({
  providedIn: 'root',
})
export class WebSocketService {
  private isConnected = false;
  private socket$?: WebSocketSubject<any>;
  private socketMsg$ = new Subject<ISocketMessage>();
  private timer?: NodeJS.Timeout;
  private connecting = false;
  private socketSub$?: Subscription;

  constructor(private storeService: StoreService) {
    this.initWs();
    this.initPing();
  }

  private initPing() {
    // Ping de prueba
    if (this.timer) {
      clearInterval(this.timer);
      this.timer = undefined;
    }
    this.timer = setInterval(() => {
      if (this.isConnected && this.socket$) {
        this.socket$.next({
          event: 'ping',
          data: 'ping',
        });
      }
    }, 30000);
  }

  public async initWs() {
    if (this.isConnected && this.socket$) {
      console.log('ya hay conexion ws');
      return;
    }

    if (this.connecting) {
      console.log(`Ya conectando`);
      return;
    }

    this.connecting = true;
    console.log(`Iniciando WebSocket ${websocketServer}`);
    const token = this.storeService.getToken();
    if (token && websocketServer) {
      try {
        this.socket$ = webSocket({
          url: websocketServer,
          openObserver: {
            next: () => {
              this.isConnected = true;
              console.log(`WebSocket abierto en ${websocketServer}`);
              this.sendToken(token);
            },
          },
          closeObserver: {
            next: () => {
              this.isConnected = false;

              console.log('WebSocket cerrado');
            },
          },
        });

        this.socketSub$ = this.socket$.subscribe(
          (message: ISocketMessage) => this.handleMessage(message),
          (error: Error) => this.handleError(error),
          () => this.closed(),
        );
      } catch (error) {
        console.log(`Error al iniciar WebSocket ${error}`);
      }
    } else {
      console.log('No hay token o websocketServer');
    }

    this.connecting = false;
  }

  private sendToken(token: string) {
    // Envio de mensaje con el token
    const identity = {
      event: 'identity',
      // data: `Bearer ${token}`
      data: {
        token: `Bearer ${token}`,
        appVersion: VERSION,
        app: NOMBRE_APP,
        appType: 'web',
      },
    };
    this.socket$?.next(identity);
  }

  public getMessage(): Observable<ISocketMessage> {
    return this.socketMsg$.asObservable();
  }

  // WS Handlers

  private async handleMessage(message: ISocketMessage) {
    this.socketMsg$.next(message);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private handleError(error: Error) {
    console.log('funcion handleError = WebSocket error reconectando...');
    this.socketSub$?.unsubscribe();
    setTimeout(async () => {
      await this.initWs();
    }, 2000);
  }

  public closeWS() {
    console.log('funcion: closeWS = Cerrando WebSocket');
    this.socket$?.complete();
    this.socketSub$?.unsubscribe();
  }

  private closed() {
    console.log('funcion closed = WebSocket closed');
    this.socketSub$?.unsubscribe();
    this.isConnected = false;
  }
}
