import { Injectable } from '@angular/core';
import {
  NOMBRE_APP,
  VERSION,
  apiMonitoreo,
} from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpService, IHttpRequestOptions } from './http.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { StoreService } from '../../entidades/login/store.service';

const api = apiMonitoreo;

@Injectable({
  providedIn: 'root',
})
export class HttpApiService {
  constructor(
    private http: HttpService,
    private httpPosta: HttpClient,
    private storeService: StoreService,
  ) {}

  public getFile(url: string, filename: string, params?: HttpParams) {
    return this.http.getFile(api + url, filename, params);
  }

  public getFile2(url: string, filename: string, params?: HttpParams) {
    return this.http.getFile2(api + url, filename, params);
  }

  public get(ruta: string, options?: IHttpRequestOptions): Observable<any> {
    return this.http.get(api + ruta, options);
  }

  public post(
    ruta: string,
    body: any,
    options?: IHttpRequestOptions,
  ): Observable<any> {
    return this.http.post(api + ruta, body, options);
  }

  public postAlertasResumen(ruta: string, body: any): Observable<any> {
    // Seteo el header de autorizacion
    const token = this.storeService.getToken();
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      appVersion: VERSION,
      app: NOMBRE_APP,
      appType: 'web',
    });

    const options: IHttpRequestOptions = {
      responseType: 'arraybuffer',
      headers,
    };

    return this.httpPosta.post(ruta, body, options);
  }

  public getAudio(ruta: string): Observable<any> {
    return this.http.get(ruta);
  }

  public put(
    ruta: string,
    body: any,
    options?: IHttpRequestOptions,
  ): Observable<any> {
    return this.http.put(api + ruta, body, options);
  }

  public delete(ruta: string): Observable<any> {
    return this.http.delete(api + ruta);
  }
}
